import EditableText from "../ViewOrderComponents/EditableText";
import React from "react";

const Sale = ({product, updateChanges}) => {
    return (
        <>
            <p className="card-title d-flex justify-content-start align-items-center">
                <b>Sale:</b>
                <input type="checkbox" className="form-check-input mx-2" checked={product.sale}
                       onClick={(e) => updateChanges("sale", e.target.checked)}></input>
            </p>
            {product.sale && (
                <EditableText keyValue="Sale Price (UAH): " initialText={product.currentPrice}
                              onSave={(newText) => updateChanges("currentPrice", Number.parseInt(newText))}/>
            )}
        </>
    )
}

export default Sale;