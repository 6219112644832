import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import {
  getOrderByUser,
  setOrderByUser,
  setSeenOrder,
  updateOrder,
} from "../features/auth/authSlice";
import "./ViewOrder.css";
import ShippingDetails from "./ViewOrderComponents/ShippingDetails";
import UserDetails from "./ViewOrderComponents/UserDetails";
import Products from "./ViewOrderComponents/Products";
import { selectOrderByUser } from "../features/auth/authSelector";
import Promocodes from "./ViewOrderComponents/Promocodes";
import ShippingDetailsCard from "./ViewOrderComponents/ShippingDetailsCard";

const StatusOptions = ["SENDING", "IN_PROGRESS", "DELIVERED"];

const ViewOrder = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [changes, setChanges] = useState({});

  useEffect(() => {
    dispatch(getOrderByUser(id));
  }, []);

  useEffect(() => {
    if (id && orderState) {
      dispatch(setSeenOrder({ orderId: Number.parseInt(id) }));
    }
  }, []);

  const orderState = useSelector(selectOrderByUser);
  const data1 = [];
  if (!orderState) return;
  let stateCopy = { ...orderState };
  console.log(stateCopy);

  data1.push({
    key: orderState.id,
    name: orderState.title,
    brand: orderState.brand,
    count: orderState.count,
    totalPrice: orderState.totalPrice,
    color: orderState.color,
    date: orderState.createdAt,
  });

  const save = () => {
    dispatch(
      updateOrder({
        id: Number.parseInt(orderState.id),
        changes,
        currentState: orderState,
      })
    );
  };

  const updateChanges = (key, value, firstKey) => {
    console.log(key, value);
    firstKey
      ? setChanges((state) => ({
          ...state,
          [firstKey]: { ...state[firstKey], [key]: value },
        }))
      : setChanges((state) => ({ ...state, [key]: value }));
    dispatch(setOrderByUser({ key, value, firstKey }));
  };

  return (
    <>
      <div className="header">
        <h3 className="mb-4 title">View Order</h3>
        <button className="save-button" onClick={save}>
          Save
        </button>
      </div>
      <div className="wrapper">
        <div className="delivery-status-container">
          <h5 className="delivery-status">Delivery Status:</h5>
          <select
            className="delivery-status-select"
            onChange={(e) => updateChanges("deliveryStatus", e.target.value)}
          >
            <option value={orderState.shippingStatus}>
              {orderState.shippingStatus}
            </option>
            {StatusOptions.map(
              (option, index) =>
                option !== orderState.shippingStatus && (
                  <option
                    key={index}
                    value={option}
                    onClick={(e) =>
                      updateChanges("deliveryStatus", e.target.value)
                    }
                  >
                    {option}
                  </option>
                )
            )}
          </select>
          <h5 className="my-2 mx-4">
            Payment status: {orderState.paymentStatus}
          </h5>
        </div>
        <div className="order-container">
          {/*<Table columns={columns} dataSource={data1}/>*/}
          <ShippingDetails
            shippingDetails={orderState.shippingDetails}
            update={updateChanges}
          />
          <UserDetails
            userDetails={orderState.userDetails}
            update={updateChanges}
          />
          <Products products={orderState.productQuantities} />
          <Promocodes promocodes={orderState.promocodes} />
          <ShippingDetailsCard>
            <p className="w-100 d-flex col-gap-20">
              {" "}
              <b>Description:</b>
              <textarea
                className="w-100 h-30-vh"
                value={orderState.description || ""}
                onChange={(e) => updateChanges("description", e.target.value)}
              />
            </p>
          </ShippingDetailsCard>
        </div>
      </div>
    </>
  );
};

export default ViewOrder;
