import EditableText from "./EditableText";
import React from "react";
import ShippingDetailsCard from "./ShippingDetailsCard";


const UserDetails = ({ userDetails, update }) => {
    const { email, firstName, lastName, phoneNumber } = userDetails;

    return (
        <ShippingDetailsCard>
            <h5 className="mb-4">User Details</h5>

            <EditableText keyValue="Email: " initialText={email} onSave={(newText) => update('email', newText, 'userDetails')} />
            <EditableText keyValue="First Name: " initialText={firstName} onSave={(newText) => update('firstName', newText, 'userDetails')} />
            <EditableText keyValue="Last Name: " initialText={lastName} onSave={(newText) => update('lastName', newText, 'userDetails')} />
            <EditableText keyValue="Phone Number: " initialText={phoneNumber} onSave={(newText) => update('phoneNumber', newText, 'userDetails')} />
        </ShippingDetailsCard>
    )
}

export default UserDetails;