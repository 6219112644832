import {React, useEffect, useState} from "react";
import CustomInput from "../components/CustomInput";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import * as yup from "yup";
import {useFormik} from "formik";
import {
    createCategory,
    getAProductCategory,
    resetState,
    updateAProductCategory,
} from "../features/pcategory/pcategorySlice";
import Images from "./ProductPageComponents/Images";
import {delImg, uploadImg} from "../features/upload/uploadSlice";
import {base_url} from "../utils/baseUrl";
import {findImageName} from "../utils/constants";

let schema = yup.object().shape({
    name: yup.string().required("Category Name is Required"),
});
const Addcat = () => {
    const dispatch = useDispatch();
    const {id} = useParams();
    const navigate = useNavigate();
    const newCategory = useSelector((state) => state.pCategory);
    const [images, setImages] = useState([]);

    useEffect(() => {
        if (id !== undefined) {
            dispatch(getAProductCategory(id));
        } else {
            dispatch(resetState());
        }
    }, [id]);

    const {
        isSuccess,
        isError,
        isLoading,
        createdCategory,
        pCategories,
        updatedCategory,
    } = newCategory;

    console.log(newCategory);
    console.log(pCategories);

    const groupName = pCategories.find((item) => item.id === id);

    if (groupName) {
        console.log(groupName);
        setImages(groupName.sizesImage);
    }

    useEffect(() => {
        if (isSuccess && createdCategory) {
            toast.success("Category Added Successfullly!");
        }
        if (isSuccess && updatedCategory) {
            toast.success("Category Updated Successfullly!");
            navigate("/admin/list-category");
        }
        if (isError) {
            toast.error("Something Went Wrong!");
        }
    }, [isSuccess, isError, isLoading]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: groupName || "",
            oneSize: false,
        },
        validationSchema: schema,
        onSubmit: (values) => {
            const data = {
                id: id,
                name: values.name,
                sizesImage: images[0],
            };
            if (id !== undefined) {
                dispatch(updateAProductCategory(data));
                dispatch(resetState());
            } else {
                dispatch(createCategory(data));
                formik.resetForm();
                setImages([]);
                setTimeout(() => {
                    dispatch(resetState());
                }, 300);
            }
        },
    });

    const uploadImage = async (image) => {
        await dispatch(uploadImg([image]));
        setImages([`${base_url}/static/${image.name}`]);
    };

    const deleteImage = async (name) => {
        setImages([]);
        await dispatch(delImg(findImageName(name)));
    };

    console.log("images", images);

    return (
        <div>
            <h3 className="mb-4  title">
                {id !== undefined ? "Edit" : "Add"} Category
            </h3>
            <div>
                <form action="" onSubmit={formik.handleSubmit}>
                    <CustomInput
                        type="text"
                        label="Enter Product Category"
                        onChng={formik.handleChange("name")}
                        onBlr={formik.handleBlur("name")}
                        val={formik.values.name}
                        id="name"
                    />
                    <div className="error mb-4  ">
                        {formik.touched.name && formik.errors.name}
                    </div>
                    <Images
                        images={images}
                        upload={uploadImage}
                        deleteImage={deleteImage}
                    />
                    <button
                        className="btn btn-success border-0 rounded-3 my-5"
                        type="submit"
                    >
                        {id !== undefined ? "Edit" : "Add"} Category
                    </button>
                </form>
            </div>
        </div>
    );
};

export default Addcat;
