import { base_url } from "./baseUrl";

export const backEndResponseUrl = `http://${base_url}/fondy/payment/success`;

export const findImageName = (name ='') => {
    const arr = name.split('/')
    for(let i = 0; i < arr.length; i++) {
        if(arr[i] === "static") {
            return arr[i + 1];
        }
    }
}