import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProduct, setProduct, updateProduct, updateProductIsActive, } from "../features/product/productSlice";
import { selectInspectedProduct } from "../features/product/productSelector";
import "./Productview.css";
import { getCategories } from "../features/pcategory/pcategorySlice";
import EditableText from "./ViewOrderComponents/EditableText";
import Images from "./ProductPageComponents/Images";
import { delImg, uploadImg } from "../features/upload/uploadSlice";
import { base_url } from "../utils/baseUrl";
import CategoryPickerWithLink from "./ProductPageComponents/CategoryPickerWithLink";
import Sizes from "./ProductPageComponents/Sizes";
import Sale from "./ProductPageComponents/Sale";
import InformationCard from "./ProductPageComponents/InformationCard";
import CardTitle from "./ProductPageComponents/CardTitle";
import { findImageName } from "../utils/constants";

const Productview = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const product = useSelector(selectInspectedProduct);
    const [changes, setChanges] = useState({});
    const catState = useSelector((state) => state.pCategory.pCategories);
    const [images, setImages] = useState([]);

    useEffect(() => {
        dispatch(getCategories());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getProduct(id));
    }, [dispatch, id]);

    useEffect(() => {
        if (product) {
            setChanges((state) => ({
                ...state,
                sale: product.sale,
                sizes: product.sizes,
            }));
            setImages(product.images);
        }
    }, [product]);

    if (!product) return;

    const getFutureId = () => {
        return Number.parseInt(((Math.random() * 1000) - (Math.random() * 100) + 100).toString());
    }
    const updateChanges = (key, value, firstKey) => {
        if (key === "sale") {
            setChanges((state) => ({
                ...state,
                currentPrice: product.originalPrice,
            }));
        }
        if (firstKey) {
            setChanges((state) => ({
                ...state,
                [firstKey]: { ...state[firstKey], [key]: value },
            }));
        } else {
            setChanges((state) => ({ ...state, [key]: value }));
        }
        dispatch(setProduct({ key, value, firstKey }));
    };

    const save = () => {
        console.log("Changes: ", changes);
        dispatch(updateProduct({ id, changes, currentState: product }));
    };

    const onActiveChange = (e) => {
        updateChanges("active", e.target.checked);
        dispatch(updateProductIsActive(id));
    };

    const uploadImage = async (image) => {
        if (!image) return;
        await dispatch(uploadImg([image]));
        setImages([...images, `${base_url}/static/${image.name}`]);
        updateChanges("images", [...images, `${base_url}/static/${image.name}`]);
    };

    const deleteImage = async (e) => {
        const result = images.filter((item) => item !== e);
        setImages(result);
        updateChanges("images", result);
        await dispatch(delImg(findImageName(e)));
    };

    // {id: number, size: string, quantity: number}
    const initialize = () => {
        const futureId = getFutureId();
        return { id: futureId, quantity: 0, size: "Specify the size" }
    }

    const addSize = (e) => {
        e.preventDefault();
        updateChanges("sizes", [...changes.sizes, initialize()])
    }

    const handleSave = (newText, id, quantity) => {
        const updatedSizes = [...changes.sizes].map((item) =>
            item.id === id
                ? quantity
                    ? { ...item, quantity: Number.parseInt(newText) }
                    : { ...item, size: newText }
                : { ...item }
        );
        console.log('updateSizes', updatedSizes);
        updateChanges("sizes", updatedSizes);
    };

    const handleRemove = (id) => {
        updateChanges("sizes", [...changes.sizes].filter(item => item.id !== Number.parseInt(id)));
    }

    // const quantityChangeHandler = (keyValue, value) => {
    //     let result = {...changes.sizes};
    //     result[keyValue] = value;
    //     console.log("Result: ", result);
    //     updateChanges("sizes", result);
    // };

    const calculateTotalQuantity = () => {
        if (!changes.sizes) return 0;
        console.log('changes.sizes: ', changes.sizes);
        let total = 0;
        for (let e of changes.sizes) {
            total += e.quantity;
        }
        return total;
    };

    const redirectToTheProductPage = () => {
        window.location.replace("https://forsbrand.com.ua/product-page/" + product.id);
    };

    return (
        <div className="main-product-view-container">
            <div className="header">
                <div>
                    <div className="d-flex align-items-end">
                        <EditableText
                            initialText={product.name}
                            onSave={(e) => updateChanges("name", e)}
                            initialTextAsHeader
                        />
                        {product.isActive && (
                            <button
                                className="link whitespace-nowrap mb-4 ml-20 font-info"
                                onClick={redirectToTheProductPage}
                            >
                                To Product Page
                            </button>
                        )}
                    </div>
                    <div className="d-flex align-items-center ">
                        <b className="font-info">Active: </b>
                        <input
                            type="checkbox"
                            className="form-check-input mx-2"
                            checked={product.isActive}
                            name="active"
                            onClick={(e) => onActiveChange(e)}
                        ></input>
                    </div>
                </div>
                <button className="save-button" onClick={save}>
                    Save
                </button>
            </div>
            <div className="product-inf-container">
                <div className="d-flex justify-content-center align-items-center flex-column">
                    <InformationCard>
                        <CategoryPickerWithLink
                            categories={catState}
                            updateChanges={updateChanges}
                            group={product.group}
                        />
                        <p className="w-100 d-flex col-gap-20">
                            {" "}
                            <b>Description:</b>
                            <textarea
                                className="w-100"
                                value={product.description}
                                onChange={(e) => updateChanges("description", e.target.value)}
                            />
                        </p>
                    </InformationCard>

                    <InformationCard>
                        <EditableText
                            initialText={product.buyPrice}
                            onSave={(newText) => updateChanges("buyPrice", Number.parseInt(newText))}
                            keyValue="Buy Price (UAH): "
                        />
                        <EditableText
                            initialText={product.originalPrice}
                            onSave={(newText) => updateChanges("originalPrice", Number.parseInt(newText))}
                            keyValue="Original Price (UAH): "
                        />
                        <Sale product={product} updateChanges={updateChanges} />
                    </InformationCard>
                </div>

                <InformationCard>
                    <Sizes
                        addNewSize={addSize}
                        defaultValue={product.sizes}
                        removeSize={handleRemove}
                        // onChange={updateSizes}
                        selectedOptions={changes.sizes}
                        quantityChangeHandler={(newText, id) => handleSave(newText, id, true)}
                        sizeChangeHandler={(newText, id) => handleSave(newText, id, false)}
                    />
                </InformationCard>

                <InformationCard>
                    <CardTitle>
                        <b>Product Quantity:</b> {calculateTotalQuantity()}{" "}
                    </CardTitle>
                    <CardTitle>
                        <b>Total Orders:</b> {product.totalOrders || 0}
                    </CardTitle>
                    <CardTitle>
                        <b>Recent Orders:</b> {product.recentOrders || 0}
                    </CardTitle>
                </InformationCard>
            </div>
            <Images images={images} upload={uploadImage} deleteImage={deleteImage} />
        </div>
    );
};

export default Productview;
