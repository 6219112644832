import React from 'react'
import './Sizes.css'
import {CustomEditableText} from '../SizesInput';
import {ReactComponent as CancelSvg} from '../../assets/cancel.svg';
// const allOptions = [
//     {value: "XS", label: "XS"},
//     {value: "S", label: "S"},
//     {value: "M", label: "M"},
//     {value: "L", label: "L"},
//     {value: "XL", label: "XL"},
//     {value: "XXL", label: "XXL"}
// ];

const Sizes = ({selectedOptions, quantityChangeHandler, sizeChangeHandler, addNewSize, removeSize}) => {

    // const value = allOptions.filter(option => defaultValue[option.value] && option)
    console.log('select options', selectedOptions);
    return (
        <div className="d-flex justify-content-start align-items-start flex-column">
            <div className='d-flex justify-content-between'>
                <h4>Sizes: </h4>
                <span className='add-new add-new-button' onClick={addNewSize}>Add New</span>
            </div>
            <div className="ml-7">
                {selectedOptions && selectedOptions.map(item => (
                    <div className='d-flex custom-edits' key={Math.random() + 100}>
                        <CustomEditableText initialText={item.size.toString().replaceAll(":", "") + ":"}
                                            handleSave={(text) => sizeChangeHandler( text, item.id)}/>
                        <CustomEditableText initialText={item.quantity.toString()}
                                            handleSave={(text) => quantityChangeHandler(text, item.id)}/>
                        <div className="pointer" onClick={() => removeSize(item.id)}>
                            <CancelSvg width={24} height={24}/>
                        </div>
                    </div>
                ))

                }
            </div>

        </div>
    )
}

export default Sizes


//         Object.entries(selectedOptions).map(([key, value]) => (
//         // <EditableText
//         //     key={key}
//         //     initialText={`${value}`}
//         //     onSave={(newText) => quantityChangeHandler(key, newText)}
//         //     keyValue={`${key}: `}
//         //     value={value}/>
//         <div className='d-flex custom-edits' key={Math.random() + 100}>
//     <CustomEditableText initialText={key.toString().replaceAll(":", "") + ":"}
//                         handleSave={(text) => sizeChangeHandler(key, text)}/>
//     <CustomEditableText initialText={value.toString()}
//                         handleSave={(text) => quantityChangeHandler(key, text)}/>
//     <div className="pointer" onClick={() => removeSize(key)}>
//         <CancelSvg width={24} height={24}/>
//     </div>
// </div>
// )