import React, { useEffect, useState } from "react";
import { config } from "../utils/axiosconfig";
import { base_url } from "../utils/baseUrl";
import axios from "axios";
import InformationCard from "./ProductPageComponents/InformationCard";
import EditableText from "./ViewOrderComponents/EditableText";
import Select from "react-select";
import InputOption from "./ProductPageComponents/InputOptions";
import { ReactComponent as CancelButton } from '../assets/cancel.svg'
import { toast } from "react-toastify";

const allOptions = [
  { value: "ADDRESS_INPUT", label: "ADDRESS_INPUT" },
  { value: "DEPARTMENT_INPUT", label: "DEPARTMENT_INPUT" },
];

const DeliveryOptions = () => {
  const [deliveryOptions, setDeliveryOptions] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const response = await axios.get(
        `${base_url}/delivery-types/getAll`,
        config
      );
      response.data && setDeliveryOptions(response.data);
    };

    fetch().then((r) => console.log(r));
  }, []);

  const onSave = async (index, key, value) => {
    const temp = [...deliveryOptions];
    temp[index][key] = value;
    setDeliveryOptions([...temp]);
    await axios.put(
      `${base_url}/delivery-types/update/${temp[index].id}`,
      { [key]: value },
      config
    );
  };

  const onChange = (e, index) => {
    console.log(e);
    if (Array.isArray(e)) {
      const value = e.length > 0 ? e.map((r) => r.value) : [];
      onSave(index, "requiredFieldsList", value).then((r) => console.log(r));
    }
  };

  const saveTempOption = async () => {
    const res = await axios.post(
      `${base_url}/delivery-types/save-temp`,
      {},
      config
    );
    if (res.status === 401) {
      window.location.href = "/";
    }
    if (res.data) {
      return res.data.id;
    }
  };

  const addNewDeliveryOptions = async (e) => {
    e.preventDefault();
    const id = await saveTempOption();
    setDeliveryOptions([
      ...deliveryOptions,
      { id, name: "", description: "", price: 0, requiredFieldsList: [] },
    ]);
  };

  const deleteDeliveryOption = async (element) => {
      const res = await axios.delete(`${base_url}/delivery-types/delete/${element.id}`, config);
      if(res.data) {
        toast.success("Delivery option")
        const response = await axios.get(
          `${base_url}/delivery-types/getAll`,
          config
        );
        response.data && setDeliveryOptions(response.data);
      } else {
        toast.error("An error occurred while trying to delete the delivery option")
      }
  }

  return (
    <>
      <div className="header">
        <h4>Delivery Options</h4>
        <button type="button" className="btn save-button" onClick={addNewDeliveryOptions}>
          Create New Delivery Option
        </button>
      </div>
      <div className="d-flex flex-wrap justify-content-start align-items-start gap-30">
        {deliveryOptions.map((r, index) => {
          const defaultValue = allOptions.filter((option) =>
            deliveryOptions[index]["requiredFieldsList"].includes(option.value)
          );

          return (
            <InformationCard key={r.id}>
              <button className="delete-button" onClick={() => deleteDeliveryOption(r)}>
                <CancelButton width={24}/>
              </button>
              <EditableText
                initialText={r.name}
                onSave={(newText) => onSave(index, "name", newText)}
                keyValue="Name: "
              />
              <p className="w-100 col-gap-20 d-flex">
                <b>Description: </b>
                <textarea
                  className="w-100"
                  value={r.description}
                  onChange={(e) => onSave(index, "description", e.target.value)}
                />
              </p>
              <EditableText
                initialText={r.price}
                onSave={(newText) => onSave(index, "price", newText)}
                keyValue="Price: "
              />
              <Select
                defaultValue={defaultValue}
                className={`mb-3 sizes-select color-black font-info w-100`}
                placeholder={
                  <span style={{ fontSize: "1rem", color: "#212529" }}>
                    Select Required Fields
                  </span>
                }
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                onChange={(e) => onChange(e, index)}
                options={allOptions}
                components={{
                  Option: InputOption,
                }}
              />
            </InformationCard>
          );
        })}
      </div>
    </>
  );
};

export default DeliveryOptions;
