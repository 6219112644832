import axios from "axios";
import axiosInstance, {config} from "../../utils/axiosconfig";
import {base_url} from "../../utils/baseUrl";
import {toast} from "react-toastify";

const getProducts = async () => {
    const response = await axios.get(`${base_url}/product/getAll`, config);
    if(response.status === 401) {
        window.location.href = "/"
      }
    return response.data;
};
const createProduct = async (product) => {
    const response = await axios.post(`${base_url}/product/create`, product, config);
    if(response.status === 401) {
        window.location.href = "/"
      }
    return response.data;
};

const updateProduct = async (id, changes, previous) => {
    const response = await axiosInstance.patch(`${base_url}/product/update/${id}`, changes, config);

    if(!response.data || response.status === 401) {
        toast.error("Unauthorized")
        return previous;
    }
    toast.success("Product updated successfully")
    return response.data;
}
export const getProduct = async (id) => {
    const response = await axiosInstance.get(`${base_url}/product/get/${id}`, config);
    if(response.status === 401) {
        window.location.href = "/"
      }
    return response.data;
}

const deleteProduct = async (id) => {
    // get is used because of the special delete function
    const res = await axiosInstance.get(`${base_url}/product/delete/${id}`, config);
    if(res.data) {
        res.data.success ? toast.success("Product deleted successfully") : toast.error("Product deleted successfully");
    }
}

const updateProductIsActive = async (id) => {
    const response = await axiosInstance.get(`${base_url}/product/active/${id}`, config)
    return response.data;
}

const productService = {
    getProducts,
    createProduct,
    updateProduct,
    getProduct,
    deleteProduct,
    updateProductIsActive,
};

export default productService;
