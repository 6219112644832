import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import MainLayout from "./components/MainLayout";
import Addcat from "./pages/Addcat";
import Addcolor from "./pages/Addcolor";
import AddCoupon from "./pages/AddCoupon";
import Addproduct from "./pages/Addproduct";
import Categorylist from "./pages/Categorylist";
import Categoryview from "./pages/Categoryview";
import Colorlist from "./pages/Colotlist";
import Couponlist from "./pages/Couponlist";
import Customers from "./pages/Customers";
import DeliveryOptions from "./pages/DeliveryOptions";
import Forgotpassword from "./pages/Forgotpassword";
import Login from "./pages/Login";
import Orders from "./pages/Orders";
import Productlist from "./pages/Productlist";
import Productview from "./pages/Productview";
import Resetpassword from "./pages/Resetpassword";
import ViewOrder from "./pages/ViewOrder";

function App() {

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/reset-password" element={<Resetpassword />} />
        <Route path="/forgot-password" element={<Forgotpassword />} />
        <Route path="/admin" element={<MainLayout />}>
          <Route path="coupon-list" element={<Couponlist />} />
          <Route path="coupon" element={<AddCoupon />} />
          <Route path="coupon/:id" element={<AddCoupon />} />
          <Route path="orders" element={<Orders />} />
          <Route path="order/:id" element={<ViewOrder />} />
          <Route path="customers" element={<Customers />} />
          <Route path="list-color" element={<Colorlist />} />
          <Route path="color" element={<Addcolor />} />
          <Route path="color/:id" element={<Addcolor />} />
          <Route path="list-category" element={<Categorylist />} />
          <Route path="category" element={<Addcat />} />
          <Route path="category/:id" element={<Categoryview />} />
          <Route path="list-product" element={<Productlist />} />
          <Route path="product" element={<Addproduct />} />
          <Route path="product/:id" element={<Productview />} />
          <Route path="delivery-options" element={<DeliveryOptions />}/>
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
