import {useState} from "react"
import EditableText from "./ViewOrderComponents/EditableText";
import './SizesInput.css'
import {ReactComponent as CancelSvg} from '../assets/cancel.svg';

const SizesInput = ({sizes, setSizes}) => {
    const [counter, setCounter] = useState(1);

    // const onSizeNameChange = (id, newText) => {
    //     const findValue = sizes.find(item => item.id === id);
    //     if (findValue) {
    //         setSizes([...sizes, { ...findValue, size: newText }])
    //     } else {
    //         alert("There was an error updating size")
    //     }
    // }

    // {id: number, size: string, quantity: number}
    const initialize = () => {
        const futureId = counter;
        setCounter(r => r + 1);
        return {id: futureId, quantity: 0, size: ""}
    }

    const onAddButtonClick = (e) => {
        e.preventDefault();
        setSizes([...sizes, initialize()])
    }

    // const onQuantityChange = (id, newQuantity) => {
    //     const findValue = sizes.find(item => item.id === id);
    //     findValue
    //         && setSizes([...sizes, { ...findValue, quantity: Number.parseInt(newQuantity) }])
    // }
    const handleSave = (newText, id, quantity) => {
        const updatedSizes = sizes.map((item) =>
            item.id === id
                ? quantity
                    ? {...item, quantity: Number.parseInt(newText)}
                    : {...item, size: newText}
                : {...item}
        );
        setSizes(updatedSizes);
    };

    const handleRemove = (id) => {
        setSizes(sizes.filter(item => item.id !== Number.parseInt(id)));
    }

    return (
        <div className="sizes-input-container">
            <span className="add-new" onClick={onAddButtonClick}>
                Add New Size
            </span>
            {
                sizes.map(item => (
                    <div className="sizes-inputs" key={item.id}>
                        <CustomEditableText handleSave={(newText) => handleSave(newText, item.id, false)}
                                            initialText={`${item.size && item.size !== "Specify the size:" ? item.size + ":" : "Specify the size:"}`}
                                            quantity={false}/>
                        {/* <EditableText initialText={item.size} onSave={(newText)=> onSizeNameChange(item.id, newText)} /> */}
                        <CustomEditableText handleSave={(newText) => handleSave(newText, item.id, true)}
                                            initialText={item.quantity}/>
                        <div className="pointer" onClick={() => handleRemove(item.id)}>
                            <CancelSvg width={24} height={24}/>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}


export default SizesInput

export const CustomEditableText = ({initialText, handleSave}) => {
    // const handleSave = (newText) => {
    //     // const updatedSizes = currentSizes.map((item) => item.id === id ? (
    //     //     !quantity ? { ...item, size: newText } : { ...item, quantity: Number.parseInt(newText) }
    //     //  ) : item);
    //     // setCurrentSizes(updatedSizes);
    //     const findValue = currentSizes.find(item => item.id === id);
    //     if (findValue) {
    //         if(quantity) {
    //             setCurrentSizes([...currentSizes,  { ...findValue, quantity: Number.parseInt(newText)}])
    //         } else {
    //             setCurrentSizes([...currentSizes, { ...findValue, size: newText }])
    //         }
    //     } else {
    //         alert("There was an error updating size")
    //     }
    // };


    return (
        <>
            <EditableText initialText={initialText} onSave={handleSave}/>
        </>
    );
};