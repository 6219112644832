import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { AiFillDelete } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { deleteProduct, getProducts } from "../features/product/productSlice";
import { Link } from "react-router-dom";

import { ReactComponent as CheckedIcon } from "../assets/checked.svg";
import { ReactComponent as NotCheckedIcon } from "../assets/notchecked.svg";
import { selectAllProducts } from "../features/product/productSelector";
import CustomModal from "../components/CustomModal";
import { base_url } from "../utils/baseUrl";
import axios from 'axios';
import { config } from "../utils/axiosconfig";

const columns = [
	{
		title: "Name",
		dataIndex: "name",
		render: (value, record) => {
			return <Link to={`/admin/product/${record.key}`}>{value}</Link>;
		},
	},
	{
		title: "Category",
		dataIndex: "category",
	},
	{
		title: "Current Price",
		dataIndex: "current_price",
	},
	{
		title: "Sale",
		dataIndex: "sale",
	},

	{
		title: "Action",
		dataIndex: "action",
	},
];

const Productlist = () => {
	const dispatch = useDispatch();
	const [open, setOpen] = useState(false);
	const [productId, setProductId] = useState("");
	const [productList, setProductList] = useState([]);

	const fetchProducts = async () => {
		const response = await axios.get(`${base_url}/product/getAll`, config);
		if (response.status === 401) {
			window.location.href = "/";
		}
		setProductList(response.data);
	}

	useEffect(() => {
		fetchProducts();
	}, []);

	const deleteHandler = async (e, id) => {
		e.preventDefault();
		setOpen(false);
		await dispatch(deleteProduct(id));
		setTimeout(() => {
			fetchProducts();
		}, 200);
	};

	const showModal = (e) => {
		setOpen(true);
		setProductId(e);
	};

	const hideModal = () => {
		setOpen(false);
	};

	// const productState = useSelector(selectAllProducts);
	// const productState = useSelector(state => state.product.products);
	// console.log("Product state: ", productState);

	const data1 = [];
	for (const element of productList) {
		data1.push({
			key: element.id,
			name: element.name,
			category: element.group && element.group.groupName,
			current_price: element.currentPrice,
			sale: element.sale ? (
				<CheckedIcon style={{ width: "20px", height: "20px" }} />
			) : (
				<NotCheckedIcon style={{ width: "20px", height: "20px" }} />
			),
			action: (
				<button
					className="ms-3 fs-3 text-danger bg-transparent border-0"
					onClick={(e) => showModal(element.id)}
				>
					<AiFillDelete />
				</button>
			),
		});
	}

	return (
		<div>
			<h3 className="mb-4 title">Products</h3>
			<Table
				columns={columns}
				dataSource={data1}
				pagination={{
					defaultPageSize: 10,
				}}
				showSorterTooltip={false}
			/>
			<CustomModal
				hideModal={hideModal}
				open={open}
				performAction={(e) => deleteHandler(e, productId)}
				title="Are you sure you want to delete this Product?"
			/>
		</div>
	);
};

export default Productlist;
