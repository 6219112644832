import axios from "axios";
import { useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as NewTabIcon } from '../../assets/new_tab.svg';
import { config } from "../../utils/axiosconfig";
import { base_url } from "../../utils/baseUrl";

const CategoryPickerWithLink = ({categories, updateChanges, group}) => {
    const [currGroup, setCurrGroup] = useState(group)

    const handleCategoryChange = async (e) => {
        const tempName = e.target.value;
        updateChanges('group', tempName);
        const res = await axios.get(`${base_url}/group/getByName?name=${tempName}`, config);
        if(res.status === 401) {
            return;
        }
        if(res.data) {
            setCurrGroup(res.data)
        }
    }

    return (
        <p className='card-title d-flex justify-content-center align-items-center'><b>Category</b>:
            <select
                name="category"
                className="form-control mx-3"
                id=""
                onChange={handleCategoryChange}
            >
                <option value="">{group?.groupName}</option>
                {categories.map((i, j) => {
                    if(!group || i.groupName !== group.groupName) {
                        return (
                            <option key={j} value={i.groupName}>
                                {i.groupName}
                            </option>
                        );
                    }
                })}
            </select>
            {currGroup && currGroup.id && <Link to={`/admin/category/${currGroup.id}`}><NewTabIcon style={{width: "20px", height: "20px"}}/></Link>}
        </p>
    )
}

export default CategoryPickerWithLink