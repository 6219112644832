import axios from "axios";
import {config} from "../../utils/axiosconfig";
import {base_url} from "../../utils/baseUrl";

const uploadImg = async (data) => {
  const response = await axios.post(`${base_url}/upload`, data, config);
  if(response.status === 401) {
    window.location.href = '/'; 
  }
  return response.data;
};
const deleteImg = async (id) => {
  const response = await axios.delete(
    `${base_url}/delete?name=${id}`,
    config
  );
  if(response.status === 401) {
    window.location.href = '/';
  }
  return response.data;
};

const uploadService = {
  uploadImg,
  deleteImg,
};

export default uploadService;
