import React, { useCallback, useEffect, useRef, useState } from "react";

import { ReactComponent as SendIcon } from "../../assets/send.svg";

const EditableText = ({ initialText, onSave, keyValue, initialTextAsHeader }) => {
    const [isEditing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const style = `${initialTextAsHeader ? 'font-header' : 'font-info'}`

    const handleEditClick = () => {
        setEditing(true);
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    const handleInputChange = (e) => {
        onSave(e.target.value);
    };

    const handleInputBlur = useCallback(() => {
        setEditing(false);
        onSave(initialText); // Call the onSave callback with the edited text
    }, [onSave, initialText]);

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (inputRef.current && !inputRef.current.contains(e.target)) {
                handleInputBlur();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleInputBlur]);

    return (
            <span onClick={handleEditClick} className="max-width"><b>{keyValue}</b>
                {isEditing ? (
                    <span className={`${style} nowrap`}>
                        <input
                            type="text"
                            // value={editedText ? editedText : ''}
                            value={initialText ? String(initialText).replaceAll("Specify the size:", "").replaceAll(":", "") : ''}
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                            ref={inputRef}
                            className="input-box max-width nowrap"
                        />
                        <button onClick={handleInputBlur} className='inside-input-button'>
                            <SendIcon style={{ width: '20px', height: '20px' }} />
                        </button>
                    </span>
                ) : (
                    initialText ? <span className={`${style} max-width nowrap`}>{initialText}</span> : <span className={`not-specified nowrap ${style}`}>Not Specified</span>
                )}
            </span>
    );
};

export default EditableText;