import './ShippingDetails.css'
import React from "react";
import EditableText from "./EditableText";
import ShippingDetailsCard from "./ShippingDetailsCard";

const ShippingDetails = ({shippingDetails, update}) => {

    const {city, country, departmentNumber, deliveryAddress, postalCode, region} = shippingDetails;

    return (
        <ShippingDetailsCard>
            <h5 className="mb-4">Shipping Details</h5>

            <EditableText keyValue="City: " initialText={city} onSave={(newText) => update("city", newText, "shippingDetails")} />
            <EditableText keyValue="Country: " initialText={country} onSave={(newText) => update("country", newText, "shippingDetails")} />
            <EditableText keyValue="Delivery Address: " initialText={deliveryAddress} onSave={(newText) => update("deliveryAddress", newText, "shippingDetails")} />
            <EditableText keyValue="Postal Code: " initialText={postalCode} onSave={(newText) => update("postalCode", newText, "shippingDetails")} />
            <EditableText keyValue="Department Number: " initialText={departmentNumber} onSave={(newText) => update("departmentNumber", newText, "shippingDetails")} />
            <EditableText keyValue="Region: " initialText={region} onSave={(newText) => update("region", newText, "shippingDetails")} />
        </ShippingDetailsCard>
    )
}

export default ShippingDetails;