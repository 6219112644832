import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import axios from "axios";
import {base_url} from "../utils/baseUrl";
import {config} from "../utils/axiosconfig";
import Images from "./ProductPageComponents/Images";
import {delImg, uploadImg} from "../features/upload/uploadSlice";
import {useDispatch} from "react-redux";
import EditableText from "./ViewOrderComponents/EditableText";
import {updateAProductCategory} from "../features/pcategory/pcategorySlice";
import {findImageName} from "../utils/constants";

const Categoryview = () => {
    const {id} = useParams();
    const dispatch = useDispatch();
    const [category, setCategory] = useState("");
    const [sizesImage, setSizesImage] = useState([]);
    const [active, setActive] = useState(true);

    useEffect(() => {
        const fetch = async () => {
            const response = await axios.get(`${base_url}/group/get/${id}`, config);
            return response.data && response.data;
        };

        fetch().then((data) => {
            setSizesImage([data.sizesImage]);
            setCategory(data.groupName);
            setActive(data.active);
        });
    }, [id]);

    const uploadImage = async (image) => {
        await dispatch(uploadImg(image));
        const imageUrl = `${base_url}/static/${image.name}`;
        setSizesImage([imageUrl]);
        dispatch(updateAProductCategory({id: id, sizesImage: imageUrl}));
    };

    const deleteImage = async (name) => {
        setSizesImage([]);
        await dispatch(delImg(findImageName(name)));
    };

    const onCategoryNameChange = (text) => {
        setCategory(text);
        dispatch(updateAProductCategory({id: id, groupName: text}));
    };

    const onActiveChange = async () => {
        const res = await axios.get(`${base_url}/group/active/${id}`, config);
        if (res.status === 401) {
            window.location.href = "/";
        }
        setActive(!active);
    };

    return (
        <>
            <EditableText
                onSave={(newText) => onCategoryNameChange(newText)}
                keyValue={<span className="font-header">Category Name: </span>}
                initialText={category}
                initialTextAsHeader
            />
            <div className="d-flex align-items-center ">
                <b className="font-info">Active: </b>
                <input
                    type="checkbox"
                    className="form-check-input mx-2"
                    checked={active}
                    name="active"
                    onClick={onActiveChange}
                ></input>
            </div>
            <Images
                images={sizesImage || null}
                deleteImage={() => deleteImage(sizesImage[0])}
                upload={uploadImage}
            />
        </>
    );
};

export default Categoryview;
