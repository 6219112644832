import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import authService from "./authServices";

const getUserfromLocalStorage = localStorage.getItem("user")
    ? localStorage.getItem("user")
    : null;

const initialState = {
    user: getUserfromLocalStorage,
    orders: [],
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: "",
};
export const login = createAsyncThunk(
    "auth/login",
    async (userData, thunkAPI) => {
        console.log(userData)
        try {
            return await authService.login(userData);
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const getOrders = createAsyncThunk(
    "order/get-orders",
    async (thunkAPI) => {
        try {
            return await authService.getOrders();
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);
export const getOrderByUser = createAsyncThunk(
    "order/get-order",
    async (id, thunkAPI) => {
        try {
            return await authService.getOrder(id);
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const setSeenOrder = createAsyncThunk(
    'order/set-seen',
    async (data, thunkAPI) => {
        try {
            return await authService.setSeenOrder(data.orderId);
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
)

export const updateOrder = createAsyncThunk(
    "order/update-order",
    async (data, thunkAPI) => {
        try {
            return await authService.updateOrder(data.id, data.changes, data.currentState);
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
)

export const authSlice = createSlice({
    name: "auth",
    initialState: initialState,
    reducers: {
        setOrderByUser(state, action) {
            const {firstKey, key, value} = action.payload
            if (firstKey) {
                if(state.orderbyuser[firstKey][key] !== value) {
                    state.orderbyuser[firstKey][key] = value + "*"
                }
            } else {
                state.orderbyuser[key] = value
            }
        }

    },
    extraReducers: (buildeer) => {
        buildeer
            .addCase(login.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(login.fulfilled, (state, action) => {
                state.isError = false;
                state.isLoading = false;
                state.isSuccess = true;
                state.user = action.payload;
                state.message = "success";
            })
            .addCase(login.rejected, (state, action) => {
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
                state.isLoading = false;
            })
            .addCase(getOrders.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getOrders.fulfilled, (state, action) => {
                state.isError = false;
                state.isLoading = false;
                state.isSuccess = true;
                state.orders = action.payload;
                state.message = "success";
            })
            .addCase(getOrders.rejected, (state, action) => {
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
                state.isLoading = false;
            })
            .addCase(getOrderByUser.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getOrderByUser.fulfilled, (state, action) => {
                state.isError = false;
                state.isLoading = false;
                state.isSuccess = true;
                state.orderbyuser = action.payload;
                state.message = "success";
            })
            .addCase(getOrderByUser.rejected, (state, action) => {
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
                state.isLoading = false;
            })
            .addCase(updateOrder.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(updateOrder.fulfilled, (state, action) => {
                state.isError = false;
                state.isLoading = false;
                state.isSuccess = true;
                state.orderbyuser = action.payload;
                state.message = "success";
            })
            .addCase(updateOrder.rejected, (state, action) => {
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
                state.isLoading = false;
            })
            .addCase(setSeenOrder.fulfilled, (state) => {
                state.isError = false;
                state.isLoading = false;
                state.isSuccess = true;
                state.orderbyuser.isNew = false;
                state.message = "success";
            });

    },
});

export const {setOrderByUser} = authSlice.actions;

export default authSlice.reducer;
