import axios from "axios";

const getTokenFromLocalStorage = localStorage.getItem("user")
    ? localStorage.getItem("user")
    : null;
console.log(getTokenFromLocalStorage)

export const config = {
    headers: {
        Authorization: `Bearer ${
            getTokenFromLocalStorage !== null ? getTokenFromLocalStorage : ""
        }`,
        Accept: "application/json",
    },

};

const axiosInstance = axios.create({
    headers: config,
});

//Add interceptors to instance
axiosInstance.interceptors.response.use(
    response => response,
    error => {
        if (!error.response) {
            window.location.href = "/";
        } else if (error.response.status === 401) {
            window.location.href = "/";
        }
        return error;
    });
export default axiosInstance;