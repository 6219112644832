

const ShippingDetailsCard = ({children}) => {

    return (
        <div className="card shipping-detailsm">
            {children}
        </div>
    )
}

export default ShippingDetailsCard;