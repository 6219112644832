import React, { useRef } from 'react'
import './Images.css'

const Images = ({ upload, deleteImage, images }) => {
    const ref = useRef()

    const handleChange = (e) => {
        console.log('called function')
        upload(e.target.files[0]);
        ref.current.value =  null;
    }

    return (
        <div className="images d-flex flex-column gap-3" style={{ marginTop: "15px" }}>
            <input onChange={handleChange} ref={ref} type='file' multiple="multiple" className="upload-button"/>
            <div className="showimages d-flex flex-wrap gap-3 images-preview-container">
                {images && images.length === 0
                    ? (
                        <div className="no-images-text">No Images Yet</div>
                    )
                    : images.map((i, j) => {
                        return (
                            <div className="position-relative " key={j}>
                                <button
                                    type="button"
                                    onClick={() => deleteImage(i)}
                                    className="btn-close position-absolute"
                                    style={{ top: "10px", right: "10px" }}
                                ></button>
                                <img src={i} alt="" width={200} height={200} />
                            </div>
                        );
                    })}
            </div>
        </div>
    )
    // const dispatch = useDispatch();
    // const imgState = useSelector((state) => state.upload.images);
    //
    // const img = [];
    // imgState.forEach((i) => {
    //     img.push({
    //       public_id: i.public_id,
    //       url: i.url,
    //     });
    //   });
    //
    // return (
    //     <>
    //         <div className="bg-white border-1 p-5 text-center">
    //             <Dropzone
    //                 onDrop={(acceptedFiles) => dispatch(uploadImg(acceptedFiles))}
    //             >
    //                 {({ getRootProps, getInputProps }) => (
    //                     <section>
    //                         <div {...getRootProps()}>
    //                             <input {...getInputProps()} />
    //                             <p>
    //                                 Drag 'n' drop some files here, or click to select files
    //                             </p>
    //                         </div>
    //                     </section>
    //                 )}
    //             </Dropzone>
    //         </div>
    //         <div className="showimages d-flex flex-wrap gap-3">
    //             {imgState?.map((i, j) => {
    //                 return (
    //                     <div className=" position-relative" key={j}>
    //                         <button
    //                             type="button"
    //                             onClick={() => dispatch(delImg(i.public_id))}
    //                             className="btn-close position-absolute"
    //                             style={{ top: "10px", right: "10px" }}
    //                         ></button>
    //                         <img src={i.url} alt="" width={200} height={200} />
    //                     </div>
    //                 );
    //             })}
    //         </div>
    //     </>
    // )
}

export default Images
