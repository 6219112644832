import React, { useEffect } from "react";
import { Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getOrders } from "../features/auth/authSlice";
import { selectOrders } from "../features/auth/authSelector";

const columns = [
	{
		title: "Shipping Status",
		dataIndex: "shippingStatus",
	},
	{
		title: "Product",
		dataIndex: "product",
	},
	{
		title: "Amount",
		dataIndex: "totalPrice",
	},
	{
		title: "Date",
		dataIndex: "date",
	},
];

const isNew = (order) => {
	return (
		<span>
			{order.shippingStatus}
			{order.isNew && <b className="text-light-blue ml-20">New!</b>}
		</span>
	);
};

const constructData = (orderState) => {
	const data = [];
	for (let i = orderState.length - 1; i >= 0; i--) {
		data.push({
			key: i + 1,
			shippingStatus: isNew(orderState[i]),
			product: <Link to={`/admin/order/${orderState[i].id}`}>View Order</Link>,
			totalPrice: orderState[i].totalPrice + " UAH",
			date: new Date(orderState[i].createdAt).toLocaleString(),
		});
	}
	return data;
};

const Orders = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getOrders());
	}, []);

	const orderState = useSelector(selectOrders);

	const data = orderState !== undefined ? constructData(orderState) : [];

	return (
		<>
			<h3 className="mb-4 title">Orders</h3>
			<Table
				columns={columns}
				dataSource={data}
				pagination={{
					defaultPageSize: 10,
				}}
			/>
		</>
	);
};

export default Orders;
