import React, { useEffect, useRef, useState } from "react";
import CustomInput from "../components/CustomInput";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { getCategories } from "../features/pcategory/pcategorySlice";
import { getColors } from "../features/color/colorSlice";

import { createProducts, resetState } from "../features/product/productSlice";
import Images from "./ProductPageComponents/Images";
import { base_url } from "../utils/baseUrl";
import { delImg, uploadImg } from "../features/upload/uploadSlice";
import { findImageName } from "../utils/constants";
import SizesInput from "./SizesInput";

let schema = yup.object().shape({
    name: yup.string().required("Product Name is Required"),
    description: yup.string().required("Description is Required"),
    price: yup.number().required("Price is Required"),
    category: yup.string().required("Category is Required"),
    buyPrice: yup.number().required("Buy Price is Required"),
});

const Addproduct = () => {
    const dispatch = useDispatch();
    const [images, setImages] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [labelValue, setLabelValue] = useState("Description");
    const [sizes, setSizes] = useState([]);
    const textAreaRef = useRef()

    useEffect(() => {
        dispatch(getCategories());
        dispatch(getColors());
    }, [dispatch]);


    const catState = useSelector((state) => state.pCategory.pCategories);
    const newProduct = useSelector((state) => state.product);
    const { isSuccess, isError, isLoading, createdProduct } = newProduct;

    useEffect(() => {
        if (isSuccess && createdProduct) {
            toast.success("Product Added Successfullly!");
        }
        if (isError) {
            toast.error("Something Went Wrong!");
        }
    }, [isSuccess, isError, isLoading, createdProduct]);


    const formik = useFormik({
        initialValues: {
            name: "",
            description: "",
            price: "",
            category: "",
            buyPrice: "",
            sizes: {},
            images: [],
        },
        validationSchema: schema,
        onSubmit: (values) => {
            formik.setFieldValue("sizes", sizes);
            console.log("values", values)
            dispatch(createProducts({ ...values, sizes: sizes }));
            formik.resetForm();
            clearSizes();
            setSelectedOptions([])
            setTimeout(() => {
                dispatch(resetState());
            }, 3000);
        },
    });


    const uploadImage = async (img) => {
        setImages(state => [...state, img.name]);
        await dispatch(uploadImg([img]))
        await formik.setFieldValue("images", [...formik.values.images, `${base_url}/static/${img.name}`])
    }
    const deleteImage = async (e) => {
        console.log(images);
        console.log(e);
        const newImages = [];
        for(let i = 0; i < images.length; i++) {
            console.log(i, images[i]);
            if(getLastPart(images[i]) != getLastPart(e)){
                if(!images[i].includes("/")){
                    newImages.push(`${base_url}/static/${images[i]}`);
                } else {
                    newImages.push(images[i]);
                }
            }
        }
        setImages(newImages);
        formik.setFieldValue("images", newImages)
        await dispatch(delImg(findImageName(e)))
    }
    const getLastPart = (string) => {
        if (!string.includes("/")) return string;
        const split = string.split("/");
        console.log("splitted:", split)
        return split.pop()
    }
    const clearSizes = () => {
        setSizes([]);
    }
    console.log(formik.values.description)

    return (
        <div>
            <h3 className="mb-4 title">Add Product</h3>
            <div>
                <form
                    onSubmit={formik.handleSubmit}
                    className="d-flex gap-3 flex-column"
                >
                    <CustomInput
                        type="text"
                        label="Enter Product Name"
                        name="name"
                        onChng={formik.handleChange("name")}
                        onBlr={formik.handleBlur("name")}
                        val={formik.values.name}
                    />
                    <div className="error">
                        {formik.touched.name && formik.errors.name}
                    </div>
                    <div className="form-floating">
                        <label htmlFor="description">{labelValue}</label>
                        <textarea
                            id="description"
                            name="description"
                            placeholder="Description"
                            className="w-100 form-control"
                            ref={textAreaRef}
                            value={formik.values.description}
                            onFocus={() => setLabelValue("")}
                            onBlur={() => setLabelValue(!formik.values.description ? "Description" : "")}
                            onChange={formik.handleChange('description')} />
                    </div>
                    <div className="error">
                        {formik.touched.description && formik.errors.description}
                    </div>
                    <SizesInput sizes={sizes} setSizes={setSizes} />
                    <CustomInput
                        type="number"
                        label="Enter Product Price"
                        name="price"
                        onChng={formik.handleChange("price")}
                        onBlr={formik.handleBlur("price")}
                        val={formik.values.price}
                    />
                    <div className="error">
                        {formik.touched.price && formik.errors.price}
                    </div>

                    <select
                        name="category"
                        onChange={formik.handleChange("category")}
                        onBlur={formik.handleBlur("category")}
                        value={formik.values.category}
                        className="form-control py-3"
                        id=""
                    >
                        <option value="">Select Category</option>
                        {catState.map((i, j) => {
                            return (
                                <option key={j} value={i.groupName}>
                                    {i.groupName}
                                </option>
                            );
                        })}
                    </select>
                    <div className="error">
                        {formik.touched.category && formik.errors.category}
                    </div>
                    <CustomInput
                        type="number"
                        label="Enter Buy Price of Product"
                        name="buyPrice"
                        onChng={formik.handleChange("buyPrice")}
                        onBlr={formik.handleBlur("buyPrice")}
                        val={formik.values.buyPrice} />

                    <Images images={formik.values.images} upload={uploadImage} deleteImage={deleteImage} />

                    <button
                        className="btn btn-success border-0 rounded-3 my-5"
                        type="submit"
                    >
                        Add Product
                    </button>
                </form>
            </div>
        </div>
    );
};

export default Addproduct;
