import React, {useEffect, useState} from "react";
import CustomInput from "../components/CustomInput";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {login} from "../features/auth/authSlice";
import './Login.css'

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const authState = useSelector((state) => state);

    const {user, isError, isSuccess, isLoading, message} = authState.auth;

    const onUsernameChange = (event) => {
        setUsername(event.target.value);
    }

    const onPasswordChange = (event) => {
        setPassword(event.target.value);
    }

    const submitHandler = (event) => {
        event.preventDefault();
        dispatch(login({username, password}));
    }

    useEffect(() => {
        if (isSuccess) {
            navigate("admin");
            window.location.reload();
        } else {
            navigate("");
        }
    }, [user, isError, isSuccess, isLoading, navigate]);

    return (
        <div className="login-container py-5" style={{background: "#ffd333", minHeight: "100vh"}}>
            <div className="login-form my-5 bg-white rounded-3 mx-auto p-4">
                <h3 className="text-center title">Login</h3>
                <div className="error text-center">
                    {message.message === "Rejected" ? "You are not an Admin" : ""}
                </div>
                <form action="" onSubmit={submitHandler}>
                    <CustomInput
                        type="text"
                        label="Username"
                        id="username"
                        name="username"
                        onChng={onUsernameChange}
                        val={username}
                    />
                    <CustomInput
                        type="password"
                        label="Password"
                        id="pass"
                        name="password"
                        onChng={onPasswordChange}
                        val={password}
                    />
                    <button
                        className="border-0 px-3 py-2 mt-3 text-white fw-bold w-100 text-center text-decoration-none fs-5"
                        style={{background: "#ffd333"}}
                        type="submit"
                    >
                        Login
                    </button>
                </form>
            </div>
        </div>
    );
};

export default Login;
