import React, {useCallback, useEffect, useState} from "react";
import {MenuFoldOutlined, MenuUnfoldOutlined} from "@ant-design/icons";
import {AiOutlineDashboard, AiOutlineShoppingCart,} from "react-icons/ai";
import {RiCouponLine} from "react-icons/ri";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {Outlet, useNavigate} from "react-router-dom";
import {ImBlog} from "react-icons/im";
import {FaClipboardList} from "react-icons/fa";
import {TbTruckDelivery} from "react-icons/tb";
import {BiCategoryAlt} from "react-icons/bi";
import {Layout, Menu, theme} from "antd";

const { Header, Sider, Content } = Layout;
const MainLayout = () => {
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const navigate = useNavigate();

  const [mobile, setMobile] = useState(window.innerWidth <= 500);
  const handleWindowSizeChange = useCallback(() => setMobile(window.innerWidth <= 500), []);
  

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, [handleWindowSizeChange]);

  useEffect(() => {
    console.log("Collaped: " + collapsed)
  }, [collapsed])
  const logOut = () => {
    localStorage.removeItem("user");
    navigate("/");
  }
  return (
    <Layout /* onContextMenu={(e) => e.preventDefault()} */>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="logo">
          <h2 className="text-white fs-5 text-center py-3 mb-0">
            <span className="sm-logo">FB</span>
            <span className="lg-logo">Forsbrand</span>
          </h2>
        </div>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={[""]}
          onClick={({ key }) => {
            if (key === "signout") {
            } else {
              navigate(key);
            }
          }}
          items={[
            {
              key: "Catalog",
              icon: <AiOutlineShoppingCart className="fs-4" onClick={() => mobile && setCollapsed(false)}/>,
              label: "Catalog",
              onClick: () => setCollapsed(false),
              children: [
                {
                  key: "product",
                  icon: <AiOutlineShoppingCart className="fs-4" onClick={() => mobile && setCollapsed(false)}/>,
                  label: "Add Product",
                  onClick: () => setCollapsed(true)
                },
                {
                  key: "list-product",
                  icon: <AiOutlineShoppingCart className="fs-4" onClick={() => mobile && setCollapsed(false)}/>,
                  label: "Product List",
                  onClick: () => setCollapsed(true)
                },
                {
                  key: "category",
                  icon: <BiCategoryAlt className="fs-4" onClick={() => mobile && setCollapsed(false)}/>,
                  label: "Category",
                  onClick: () => setCollapsed(true)
                },
                {
                  key: "list-category",
                  icon: <BiCategoryAlt className="fs-4" onClick={() => mobile && setCollapsed(false)}/>,
                  label: "Category List",
                  onClick: () => setCollapsed(true)
                },
              ],
            },
            {
              key: "orders",
              icon: <FaClipboardList className="fs-4" />,
              label: "Orders",
              onClick: () => setCollapsed(true)
            },
            {
              key: "Promocodes",
              icon: <RiCouponLine className="fs-4" />,
              label: "Promocodes",
              children: [
                {
                  key: "coupon",
                  icon: <ImBlog className="fs-4" />,
                  label: "Add Coupon",
                  onClick: () => setCollapsed(true)
                },
                {
                  key: "coupon-list",
                  icon: <RiCouponLine className="fs-4" />,
                  label: "Coupon List",
                  onClick: () => setCollapsed(true)
                },
              ],
            },
            {
              key: "delivery-options",
              icon: <TbTruckDelivery className="fs-4"/>,
              label: "Delivery Options",
              onClick: () => setCollapsed(true)
            }
          ]}
        />
      </Sider>
      <Layout className="site-layout">
        <Header
          className="d-flex justify-content-between ps-1 pe-5"
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        >
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger",
              onClick: () => setCollapsed(!collapsed),
            }
          )}
          <div className="d-flex gap-4 align-items-center">


            <div className="d-flex gap-3 align-items-center dropdown">

                  <button
                    className="dropdown-item py-1 mb-1"
                    style={{ height: "auto", lineHeight: "20px" }}
                    onClick={logOut}
                  >
                    Log out
                  </button>
              </div>
          </div>
        </Header>
        <Content
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: 280,
            background: colorBgContainer,
          }}
        >
          <ToastContainer
            position="top-right"
            autoClose={250}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            theme="light"
          />
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};
export default MainLayout;
