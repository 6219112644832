import axiosInstance, {config} from "../../utils/axiosconfig";
import {base_url} from "../../utils/baseUrl";
import {toast} from "react-toastify";
import axios from "axios";

const login = async (user) => {
    const response = await axiosInstance.post(`${base_url}/auth/login`, user);
    if(!response.status.toString().startsWith('2')) return;
    if (response.data && response.data.token) {
        localStorage.setItem("user", response.data.token);
    }
    return response.data;
};
const getOrders = async () => {
    const response = await axiosInstance.get(`${base_url}/order/getAll`, config);

    return response.data;
};
const getOrder = async (id) => {
    const response = await axiosInstance.get(
        `${base_url}/order/get/${id}`,
        config
    );
    if(!response.data) {
        toast.error("Order not found")
    }
    return response.data;
};

const updateOrder = async (orderId, changes, previous) => {
    const response = await axiosInstance.patch(`${base_url}/order/update?id=${orderId}`, changes, config);
    if (response.status === 401) {
        toast.error("Unauthorized")
        return previous;
    }
    toast.success("Order updated successfully")
    return response.data;
}

const setSeenOrder = async (orderId) => {
    await axiosInstance.get(`${base_url}/order/seen/${orderId}`, config)
}

const authService = {
    login,
    getOrders,
    getOrder,
    updateOrder,
    setSeenOrder,
};

export default authService;
