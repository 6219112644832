import {createSelector} from "reselect";

const authSelectorReducer = (state) => state.auth;

export const selectOrderByUser = createSelector(
    [authSelectorReducer],
    (state) => state.orderbyuser
)

export const selectOrders = createSelector(
    [authSelectorReducer],
    (state) => state.orders
)