import CardTitle from "../ProductPageComponents/CardTitle";
import ShippingDetailsCard from "./ShippingDetailsCard";


const Promocodes = ({ promocodes }) => {


    return (
        <ShippingDetailsCard>
            <h5 className="mb-4">Promocode</h5>
            <CardTitle>
                <b>Promocode: </b>{promocodes 
                ? (promocodes.promocode || <span className="text-muted">Not Specified</span>) 
                : (<span className="text-muted">Not Specified</span>)}
            </CardTitle>
            <CardTitle>
                {
                promocodes && 
                    (<><b>Promocode Discount: </b> {promocodes.discount +"%"}</>) 
                }
            </CardTitle>
        </ShippingDetailsCard>
    )
}

export default Promocodes;