import axios from "axios";
import {base_url} from "../../utils/baseUrl";
import {config} from "../../utils/axiosconfig";

const getProductCategories = async () => {
  const response = await axios.get(`${base_url}/group/getAll`, config);
  if(response.status === 401) {
    window.location.href = "/"
  }
  return response.data;
};
const createCategory = async (category) => {
  const data = {name: category.name, isOneSize: category.isOneSize, sizesImage: category.sizesImage};
  const response = await axios.post(`${base_url}/group/create`, data, config);
  if(response.status === 401) {
    window.location.href = "/"
  }
  return response.data;
};

const getProductCategory = async (id) => {
  const response = await axios.get(`${base_url}/group/get/${id}`, config);
  if(response.status === 401) {
    window.location.href = "/"
  }
  return response.data;
};

const deleteProductCategory = async (id) => {
  const response = await axios.delete(`${base_url}/group/delete/${id}`, config);
  if(response.status === 401) {
    window.location.href = "/"
  }
  return response.data;
};
const updateProductCategory = async (category) => {
  console.log(category);
  const response = await axios.patch(
    `${base_url}/group/update/${category.id}`,
    { groupName: category.groupName, sizesImage: category.sizesImage },
    config
  );
  if(response.status === 401) {
    window.location.href = "/"
  }

  return response.data;
};
const pCategoryService = {
  getProductCategories,
  createCategory,
  getProductCategory,
  deleteProductCategory,
  updateProductCategory,
};

export default pCategoryService;
