import {createSelector} from "reselect";


const productSelectorReducer = (state) => state.product


export const selectInspectedProduct = createSelector(
    [productSelectorReducer],
    (product) => product.product
)

export const selectAllProducts = createSelector(
    [productSelectorReducer],
    (product) => product.products
)