import './Products.css'
import {useNavigate} from "react-router-dom";
import ShippingDetailsCard from "./ShippingDetailsCard";

const Products = ({products}) => {

    const navigate = useNavigate()

    const handleProductClick = (product) => {
        navigate(`/admin/product/${product.product.id}`)
    }

    return (
        <ShippingDetailsCard>
            {
                products.map((product) => {
                    return (
                        <div key={product.id} className="product-container mb-3" onClick={() => handleProductClick(product)}>
                            <span>Product id: {product.id}</span>
                            <span>Product Name: {product.product.name} </span>
                            <span>Quantity: {product.quantity} </span>
                            <span>Size: {product.size}</span>
                        </div>)
                })
            }
        </ShippingDetailsCard>
    )
}

export default Products