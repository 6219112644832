import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import productService from "./productService";

export const getProducts = createAsyncThunk(
    "product/getAll",
    async (thunkAPI) => {
        try {
            return await productService.getProducts();
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const getProduct = createAsyncThunk(
    "product/get",
    async (id, thunkAPI) => {
        try {
            return await productService.getProduct(id);
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
)
export const createProducts = createAsyncThunk(
    "product/create",
    async (productData, thunkAPI) => {
        console.log(productData)
        try {
            return await productService.createProduct(productData);
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const deleteProduct = createAsyncThunk(
    "product/delete",
    async (id, thunkAPI) => {
        try {
            return await productService.deleteProduct(id);
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
)
export const updateProductIsActive = createAsyncThunk(
    "product/updateIsActive",
    async (id, thunkAPI) => {
        try {
            return await productService.updateProductIsActive(id);
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
)
export const updateProduct = createAsyncThunk(
    "product/update",
    async (productData, thunkAPI) => {
        try {
            return await productService.updateProduct(productData.id, productData.changes, productData.currentState);
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
)
export const resetState = createAction("Reset_all");

const initialState = {
    products: [],
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: "",
};

export const setFirst = (product, products = []) => {
    const newProducts = [];
    for(let idx in products) {
        if(Number.parseInt(products[idx].id) === Number.parseInt(product.id)) {
            continue;
        }
        newProducts.push(products[idx]);
    }
    products.push(product);
    return newProducts;
}

export const productSlice = createSlice({
    name: "products",
    initialState,
    reducers: {
        updateSale: (state, action) => {
            state.product.sale = action.payload;
        },
        setProduct(state, action) {
            const { key, value, firstKey } = action.payload;
            if (firstKey) {
                if (state.product[firstKey][key] !== value) {
                    state.product[firstKey][key] = value + "*";
                }
            } else {
                state.product[key] = value
            }
        },
        getProducts(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.isSuccess = true;
            state.products = action.payload;
        },
        setProducts(state, action) {
            state.products = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProducts.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getProducts.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.products = action.payload;
            })
            .addCase(getProducts.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(createProducts.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(createProducts.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.createdProduct = action.payload;
            })
            .addCase(createProducts.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(getProduct.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getProduct.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.product = action.payload;
            })
            .addCase(updateProduct.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.products = setFirst(action.payload, state.products);
            })
            .addCase(getProduct.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(updateProductIsActive.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.product = { ...state.product, isActive: action.payload.isActive };
            })
            .addCase(deleteProduct.fulfilled, (state) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
            })
            .addCase(resetState, () => initialState);
    },
});

export const {
    setProduct, setProducts
} = productSlice.actions;

export default productSlice.reducer;
